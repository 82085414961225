<template src="./ChangePassword.html">
</template>

<script>

    export default {
        name: "ChangePassword",

        created() {
        },

        computed: {
            //============== Validations ===========//
            checkPasswordRules() { return this.$validation.checkValueRules; },
            checkValueRules() { return this.$validation.checkValueRules; },
        },

        data: () => ({
            changePasswordValid: true,
            changePassword: {
                oldPassword: null,
                newPassword: null,
                confirmPassword: null,
            },
            showOldPass: false,
            showNewPass: false,
            showConfirmPass: false,
        }),

        methods: {
           
            updatePassword() {
                if (this.$refs.changePassword.validate()) {
                    if (this.changePassword.newPassword !== this.changePassword.confirmPassword) {
                        this.$store.commit("snackbar/show",
                            { message: 'تأكيد كلمة المرور غير مطابقة', icon: "mdi-information-outline", type: "warning" }
                        );
                        return;
                    }
                    this.$http.changePassword(this.changePassword)
                        .then(res => {
                            this.$store.commit("snackbar/show",
                                { message: res.data.msg, icon: "mdi-check-all", type: "success" }
                            );
                            this.$router.push({ name: 'Home' })
                        })
                        .catch(err => {
                            this.$store.commit("snackbar/show",
                                { message: err.response.data.msg, icon: "mdi-alert-circle-outline", type: "error" }
                            );
                        });
                }
                else {
                    this.$store.commit("snackbar/show",
                        { message: 'الرجاء التحقق من البيانات', icon: "mdi-information-outline", type: "warning" }
                    );
                }
            },

            resetChangePassword() {
                this.$refs.changePassword.reset()
            },
        }
    };
</script>

<style scoped lnag="scss"></style>
